<template>
  <div>
    <BannerView :dataThankyou="programData" />
    <AboutView />
    <AwardsView />
    <OnlineProgram />
    <HiglightView />
    <WhoApply />
    <HiringView />
    <CareerSupport />
    <FaqView v-if="this.lpProgramfaq" :faq="this.lpProgramfaq" />
    <div class="pb-[80px] md:pb-0">
      <FooterView :phone="programData.phone_number" />
    </div>
    <LpStickyView
      :wattsapp="programData.whatsapp_url"
      :phone="programData.phone_number"
    />
  </div>
</template>

<script>
import { useHead } from "@unhead/vue";
import axios from "axios";
import BannerView from "@/components/LearningShalaThankyou/BannerView.vue";
import AboutView from "@/components/LearningShala/AboutView.vue";
import AwardsView from "@/components/LearningShala/AwardsView.vue";
import OnlineProgram from "@/components/LearningShala/OnlineProgram.vue";
import HiglightView from "@/components/LearningShala/HiglightView.vue";
import WhoApply from "@/components/LearningShala/WhoApply.vue";
import HiringView from "@/components/LearningShala/HiringView.vue";
import CareerSupport from "@/components/LearningShala/CareerSupport.vue";
import FaqView from "@/components/LearningShala/FaqView.vue";
import FooterView from "@/components/LearningShala/FooterView.vue";
import LpStickyView from "@/components/LearningShala/LpStickyView.vue";

export default {
  name: "LP_ProgramThankyou",
  props: ["slug"],
  data() {
    return {
      programData: [],
    };
  },
  components: {
    BannerView,
    AboutView,
    AwardsView,
    OnlineProgram,
    HiglightView,
    WhoApply,
    HiringView,
    CareerSupport,
    FaqView,
    FooterView,
    LpStickyView,
  },
  created() {
    this.programThankyouInfo();
    let path = this.$route.path;
    console.log("path :", path);
    if (this.slug == "best-online-degree-programs") {
      if (path == "/lp/best-online-degree-programs/thankyou") {
        useHead({
          script: [
            {
              src: "https://www.googletagmanager.com/gtag/js?id=AW-11473738362",
              async: true,
            },
            {
              innerHTML: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-11473738362');
          `,
              type: "text/javascript",
            },
          ],
          __dangerouslyDisableSanitizers: {
            script: ["innerHTML"],
          },
        });
      }
    }
    if (this.slug == "accredited-online-degrees") {
      if (path == "/lp/accredited-online-degrees/thankyou") {
        useHead({
          script: [
            {
              src: "https://www.googletagmanager.com/gtag/js?id=AW-16645857977",
              async: true,
            },
            {
              innerHTML: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16645857977');
          `,
              type: "text/javascript",
            },
            {
              innerHTML: `gtag('event', 'conversion', {'send_to': 'AW-16645857977/4bvXCMjXvcMZELnFroE-'});`,
            },
          ],
          __dangerouslyDisableSanitizers: {
            script: ["innerHTML"],
          },
        });
      }
    }
  },
  methods: {
    async programThankyouInfo() {
      await axios
        .get(`${process.env.VUE_APP_API}/lp/electives/?slug=${this.slug}`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.programData = resp.data.data[0];
            this.status = true;
            console.log("program data : ", this.programData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
